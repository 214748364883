import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../../Modal/Modal';
import { Send } from '../../Icons/IconLibrary';
import ContactsSearch from '../../ContactsSearch/ContactsSearch';
import LoadingButton from '../../Buttons/LoadingButton';
import './SendBatchSheetToButton.css'
import UnitSelector from '../../UnitSelector/UnitSelector';
class SendBatchSheetToButton extends React.Component {
  constructor(props) {
    super(props);
    this.onSendEmails = this.onSendEmails.bind(this);
    this.timerID = null;
    this.state = {
      recipients: '',
      showModal: false,
      message: '',
      urgent: false,
      subject: '',
      loading: false,
      batch_sheet_name: '',
      currentUnits: 'kg',
      weightBasis: 0,
      user_defined_id: ''
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.recipe != this.props.recipe) {
      this.setState({
        batch_sheet_name: this.props.recipe.name,

      })
    }
    if (prevProps.doc != this.props.doc) {
      this.setState({
        user_defined_id: this.props.doc.user_defined_id
      })
    }

  }
  changeUnits = (units) => {
    this.setState({ currentUnits: units });
  }
  onChangeWeightBasis = (e) => {
    this.setState({ weightBasis: e.target.value });
  }
  toggleUrgent = () => {
    this.setState({ urgent: !this.state.urgent })
  }
  onShowModal = () => {

    this.setState({ showModal: true })
  }
  onHideModal = () => {
    this.setState({
      showModal: false, only_as_pdf: false
    })
  }
  onSendEmails(emails) {
    this.setState({ recipients: emails })
  }
  onEnterRecipient = (e) => {
    this.setState({
      recipients: e.target.value,
    });
  }
  changeBatchSheetName = (e) => {
    this.setState({ batch_sheet_name: e.target.value })
  }
  changeBatchID = (e) => {
    this.setState({ user_defined_id: e.target.value })
  }
  onChangeDocumentInfo = (e) => {
    this.setState({
      message: e.target.value,
    });
  }
  onChangeSubject = (e) => {
    this.setState({
      subject: e.target.value,
    });
  }
  onSendDocument = () => {
    let recipe = this.props.recipe;
    if (recipe.total_weight_ratio != 100) {
      return toast.error('This recipe does not add up to 100%. Incomplete recipes can not generate batch sheets.')
    }
    if (recipe.requires_signatures) {
      let signatures = this.props.doc.signatures;
      let signaturesStatuses = signatures.map(signature => { return signature.status })

      if (signaturesStatuses.includes('Pending') || signaturesStatuses.includes('Rejected')) {
        return toast.error('This recipe has not collected all approval signatures and cannot be locked.')
      }
      if (signatures == 0) {
        return toast.error('This recipe requires signatures and no signatures have been collected.')
      }
    }
    let toastID = toast.info('Sending batch sheet...', { autoClose: false });
    if (this.state.recipients === '') {
      toast.update(toastID, { render: `Please enter a recipient`, type: toast.TYPE.ERROR, autoClose: 5000 });
      return;
    }
    let recipients = this.state.recipients.concat();
    this.setState({ loading: true });
    let weight_basis = this.state.weightBasis + ' ' + this.state.currentUnits;
    Request.authRequest(Routes.recipes.sendBatchSheet(recipe.id),
      'POST', JSON.stringify({
        name: this.state.batch_sheet_name.trim(),
        email: recipients,
        message: this.state.message,
        subject: this.state.subject,
        weight_basis: weight_basis,
        user_defined_id: this.state.user_defined_id
      }), 'application/json'
    ).then(response => {
      if (response.status === 200) {
        toast.update(toastID, {
          render: `Successfully sent batch sheet to all
                recipients.`, type: toast.TYPE.SUCCESS, autoClose: 5000
        });
        this.setState({ loading: false, urgent: false, message: '', recipients: '', subject: '', only_as_pdf: false, showModal: false })
        if (this.props.viewer) {
          this.props.getRecipe();
        }

      } else {
        response.json().then((error) => {
          this.setState({ loading: false })
          toast.update(toastID, {
            render: `Could not send some of your documents. Please try again.`
            , type: toast.TYPE.ERROR, autoClose: 5000
          });
          toast.error(error.message);
          if (error.errors) {
            toast.error(error.errors[0])
          }
        });
      }


    }).catch((error) => {
      console.log(error);
      toast.update(toastID, {
        render: `Could not send some of your documents. Please contact Ivy Help.`
        , type: toast.TYPE.ERROR, autoClose: 5000
      });
    }
    )
  }

  render() {
    let recipe = this.props.recipe;
    let immutableText = "Sending a batch sheet will mark this version of the recipe as finalized."

    let placeholderName;
    if (recipe) {
      if (recipe.immutable) {
        immutableText = '';
      }
      placeholderName = 'Enter a name for this batch sheet';
    }
    return (
      <div >
        <button className='headerButton noselect' id="buttonSendDocumentTo" onClick={this.onShowModal}
          alt='Send currently selected documents to someone' title='Send batch sheet to receiver' >
          Send
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Send Documents' >

          <div className="batchSheetSend-modalContainer">
            <div>
              <div>{immutableText}</div>
              <div>Enter a name for the batch sheet.</div>
              <input value={this.state.batch_sheet_name} className="batchSheetInput-name"
                onChange={this.changeBatchSheetName} placeholder={placeholderName} type='string' />
              <div>Enter an id for batch sheet.</div>
              <input value={this.state.user_defined_id} className="batchSheetInput-name"
                onChange={this.changeBatchID} type='string' />
              <div className="helpText-CreateTagButton">Enter the emails of the recipients to send
                the batch sheet to.
              </div>

              <ContactsSearch onSendEmails={this.onSendEmails} />
              <div>
                <div className="sendDocuments-subjectLine">
                  <div>Optional Subject Line</div>
                  <div className="sendDocuments-urgentCheckbox">
                    <input
                      checked={this.state.urgent}
                      name='expiresBoolean'
                      type='checkbox'
                      id='expiresBoolean'
                      onChange={this.toggleUrgent}
                      title="Adds Urgent to the subject line." />
                    <div>Mark as Urgent
                    </div>
                  </div>
                </div>
                <textarea
                  value={this.state.subject}
                  type='text'
                  className='send-message-field'
                  onChange={this.onChangeSubject}
                  placeholder="Optional Subject" />
              </div>
              <div>Optional Message</div>
              <textarea
                value={this.state.string}
                type='text'
                className='send-message-field'
                id="send-message-message"
                onChange={this.onChangeDocumentInfo}
                placeholder="Optional Message" />
            </div>
            <div>
              <div>Enter an initial weight basis for this batch sheet.</div>
              <div className="batchSheetSend-unitContainer">
                <input type="float" onChange={this.onChangeWeightBasis} value={this.state.weightBasis} />
                <UnitSelector changeUnits={this.changeUnits} unit={this.state.currentUnits} ignore_density={this.props.ignore_density} />
              </div>
            </div>
          </div>
          <button className='modal-button primary'>
            <LoadingButton onClick={this.onSendDocument}
              text='Send' isLoading={this.state.loading} />
          </button>

        </Modal>
      </div>
    )


  }
}
export default SendBatchSheetToButton;
