import React from 'react';
import ReleaseCriteriaTable from '../RecipeViewer/ReleaseCriteriaTable';
import BatchSheetPreviewRow from './BatchSheetPreviewRow';
import UnitSelector from '../../UnitSelector/UnitSelector';
import './BatchSheetViewer.css';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import BillOfMaterials from './BillOfMaterials';
import BatchSheetDataDownload from './BatchSheetDataDownload'
class RecipeBatchSheetPreviewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weightBasis_kg: 100,
            currentUnits: 'kg',
            recipe: '',
            userRole: 'USER'
        }
    }
    changeUnits = (units) => {
        this.setState({ currentUnits: units });
    }
    onChangeWeightBasis = (e) => {
        this.setState({ weightBasis: e.target.value });
    }
    componentDidMount = () => {
        this.getRecipeBatchPreview();
        this.getUser();
    }
    getUser = () => {
        Request.authRequest(Routes.profile.view, 'GET').then((response) => {
            response.json().then(body => {
                this.setState({
                    userRole: body.recipe_role

                })
            })
        });
    }
    getRecipeBatchPreview = () => {
        Request.authRequest(Routes.recipes.previewBatchSheet(this.props.recipe.id),
            'GET').then((response) => {

                if (response.status === 200) {
                    response.json().then(body => {
                        this.setState({ recipe: body, loaded: true })
                    })
                }
                else {
                    response.json().then((error) => {
                        this.setState({ loaded: true })
                        toast.error(error.message);
                    });
                }
            }).catch((error) => {
                toast.error(error.message);
            }
            )
    }
    onCalculateNewValues = () => {
        let weight_basis = this.state.weightBasis + ' ' + this.state.currentUnits

        Request.authRequest(Routes.recipes.previewBatchSheet(this.props.recipe.id),
            'PUT', JSON.stringify(
                { weight_basis: weight_basis }
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.setState({ recipe: body })
                });
            } else {
                response.json().then((error) => {
                    toast.error(error.message);
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )

    }
    render() {
        let recipe = this.props.recipe;
        let batch_sheet = this.state.recipe;
        if (!batch_sheet) {
            return null
        }
        let ingredients = batch_sheet.recipe_ingredients.map((ingredient, i) => {
            return <BatchSheetPreviewRow ingredient={ingredient} key={i}
                ignore_density={this.props.ignore_density} />
        })
        let releaseCriteria = recipe.release_criteria;
        return (
            <div>
                <div>
                    {this.state.userRole == 'Super Admin' ? <BatchSheetDataDownload recipe={this.state.recipe} input_batch_size={this.state.weightBasis}
                        input_batch_units={this.state.currentUnits} recipe_name={this.props.recipe.name} /> : null}

                    <div className="batchSheet-sectionContainer">
                        <div className="batchSheet-sectionHeader">Batch Size</div>
                        <div className="batchSheet-weightBasis">
                            <input type="float" onChange={this.onChangeWeightBasis} value={this.state.weightBasis} placeholder={batch_sheet.weight_basis_kg} />
                            <UnitSelector changeUnits={this.changeUnits} unit={this.state.currentUnits} ignore_density={this.props.ignore_density} />
                        </div>
                        <div className="batchSheet-calculateWeightBasis" onClick={this.onCalculateNewValues}>Calculate</div>
                    </div>
                    <div className="batchSheet-overall ">
                        <table className="batchSheetTable">
                            <tbody className="batchSheetTable batchSheetWeightBasis">
                                <tr className="batchSheetTable-row-headers batchSheetHeader">
                                    <th>Kilograms</th>
                                    <th>Pounds</th>
                                    {this.props.ignore_density ? null : <th>Liters</th>}
                                    {this.props.ignore_density ? null : <th>Gallons</th>}
                                </tr>

                                <tr className="batchSheetTable-row-headers">
                                    <td>{batch_sheet.f_weight_kg}</td>
                                    <td>{batch_sheet.f_weight_lb}</td>
                                    {this.props.ignore_density ? null : <td>{batch_sheet.f_vol_L}</td>}
                                    {this.props.ignore_density ? null : <td>{batch_sheet.f_vol_gal}</td>}
                                </tr>
                            </tbody>
                        </table>
                        {this.props.ignore_density ? null : <div>Total Density: {batch_sheet.f_total_density_kg_L} {batch_sheet.f_total_density_lb_gal}</div>}
                    </div>
                </div>
                <div className="batchSheet-sectionContainer">
                    <div className="batchSheet-sectionHeader">Ingredients</div>
                    <table className="batchSheetTable">
                        <tr className="batchSheetTable-row-headers">
                            <th className="ingredient-name">Ingredient</th>
                            <th className="ingredient-percent">%</th>

                            <th className="batchSheet-field">Kilograms</th>
                            <th className="batchSheet-field">Pounds</th>
                            {this.props.ignore_density ? null : <th className="batchSheet-field">Liters</th>}
                            {this.props.ignore_density ? null : <th className="batchSheet-field">Gallons</th>}
                            {this.props.ignore_density ? null : <th className="batchSheet-minor-field">kg/L</th>}
                            {this.props.ignore_density ? null : <th className="batchSheet-minor-field">lb/gal</th>}
                        </tr>

                        {ingredients}
                    </table>
                </div>
                <div>
                    <ReleaseCriteriaTable releaseCriteria={releaseCriteria} recipe={recipe}
                        onGetRecipe={this.onGetRecipe} batchSheet
                        onGetLogs={this.onGetLogs} />
                </div>
            </div>
        )
    }
}
export default RecipeBatchSheetPreviewer;