import React from 'react';
import PreviewButton from '../../Buttons/PreviewButton';
import InfoField from '../InfoField';
import DocumentDateField from '../DocumentDateField';
import DocumentNotesField from '../DocumentNotesField';
import TagsField from '../TagsField';
import Request from '../../../helpers/Request';
import Routes from '../../../helpers/Routes';
import { Link } from 'react-router-dom';
import {toast} from 'react-toastify';
import '../DocumentPreview.css';
import TagCleaner from '../../../helpers/TagCleaner';
class DocumentPreview extends React.Component{
  constructor(props) {
		super(props);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.onSaveTagsChanges = this.onSaveTagsChanges.bind(this);
		this.state = {
			error: '',
      locked:true,
			preview:false,
      doc:props.doc
		}
	}
  componentDidUpdate(prevProps,prevState){
    if (prevProps.doc!==this.props.doc){

        this.setState({doc:this.props.doc})

    }

  }
  onSaveChanges = (contentType,content) =>{
    let doc =  JSON.parse(JSON.stringify(this.state.doc));

    switch (contentType) {
      case 'ID':
        doc.user_defined_id=content;
        break;
      case 'Name':
        doc.name = content;
        break;
      case 'Category':
        doc.category=content;
        break;
      case 'Notes':
        doc.notes = content;
        break;
      default:
      return;
      }
      Request.authRequest(Routes.documents.edit(this.props.doc.batch_sheet_id),
                        'PUT',JSON.stringify(
                  				doc
                  			), 'application/json'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {

                            this.props.onGetDocuments();
                            this.setState({doc:body})
                    });
                  } else {
                    response.json().then((error) => {
                      console.log(error);
                      toast.error(error.message);
                    });
                  }
                }).catch((error) => {
                  toast.error(error.message);
                  }
    )

  }
  onSaveTagsChanges = (tagsArray) =>{
    /* this string, unlike other tags operations is the whole tags list */
    let doc = this.state.doc;
    if (tagsArray.length ===0){
      toast.error('Please enter tags. All documents must have at least one tag.')
      return
    }
    let trimmedTags = TagCleaner.parseTagArray(tagsArray);
    Request.authRequest(Routes.documents.tag(doc.batch_sheet_id),
                        'PUT',JSON.stringify({
                          tag_names: trimmedTags,
                        }), 'application/json'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {
                            this.props.onGetDocuments();
                            this.props.onGetTags();
                            toast.success('Successfully applied tags!')
                            this.setState({doc:body})
                          });
                        } else {
                          response.json().then((error) => {
                            toast.error(error.message);
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
  }

  onRenderPreviewButtons(linkURL){

    return(
      <div>
        <PreviewButton doc  = {this.state.doc}/>
          <Link to = {linkURL} className = "button-documentView-header ">
            More Info
          </Link>
        </div>
    )

  }

	render() {

		const  doc  = this.state.doc;
    let linkURL = `/batch_sheet/`;
    let date_created;
    let versionNumber;
    let status;
    let statusWords;
    let is_receiver = doc.batch_sheet.is_receiver;
    let send_receive = 'Sent to ' + doc.batch_sheet.receiving_organization_name
    if (is_receiver){
      send_receive = 'From ' + doc.batch_sheet.sending_organization_name;
    }
      linkURL=`/batch_sheets/`+doc.batch_sheet_id
      date_created = new Date(doc.date_created);
      statusWords = doc.status;
      if (doc.tags && doc.tags.length>0){
        let tag_names = doc.tags.map((entity,i)=>{
            return entity.name;
         })
        if (tag_names.indexOf("Draft")>-1){
          statusWords = "DRAFT";
        }
        if (tag_names.indexOf("Archive")>-1){
          statusWords = "Archive";
        }
      }

      status =	<div className ="documentViewer-uneditableHelpText noselect">Status: {statusWords}</div>;

    


    if (doc ===[] || !doc){
      return (
        <div className='documents-preview boxShadow'>
  		  	<div className='documents-preview-header'>
            <div className = 'documents-preview-name'>

            </div>
            <div className = "buttons-docPreview noselect">
              <span onClick ={this.props.onCloseDocPreview}
                className = 'button-closeDocPreview'>
                  X
                </span>
                {doc!==null? this.onRenderPreviewButtons(linkURL):null}
              </div>
  		  	</div>
          <div className = "helpText">Select a recipe to see information</div>
          </div>
      )
    }
    
    return (
			<div className='documents-preview boxShadow'>
		  	<div className='documents-preview-header'>

          <div className = "buttons-docPreview noselect">
            <span onClick ={this.props.onCloseDocPreview}
              className = 'button-closeDocPreview'>
                X
              </span>
              
            </div>
		  	</div>
        {doc!==null?
        <div className = 'documents-preview-body'>
          <InfoField content = {doc.user_defined_id} contentType = 'ID'
            doc = {doc} onClickOut={this.onSaveChanges}/>
          <InfoField content = {doc.name} contentType = 'Name' doc = {doc}
            onClickOut={this.onSaveChanges}/>
         
              <DocumentNotesField className = "document-preview-notes" content = {doc.notes} contentType = 'Notes'
            doc = {doc} onClickOut={this.onSaveChanges}/>

          <div className ="docPreview-tagscontainer">
            <TagsField content = {doc.tags} contentType = 'Tags' doc = {doc}
              onClickOut={this.onSaveTagsChanges}/>

          </div>
          <div  className= "recipePreview-ingredients-container">
            <div className= "recipePreview-numIngredients">Ingredients: </div>
                <div className= "recipePreview-ingredients"></div></div>
          {status}
          <div className = "docPreview-date-created helpText">Created {date_created.toDateString()}
            <br/>
            {versionNumber}
          </div>
          <div className = "docPreview-date-created helpText"> {send_receive}</div>
          <Link to = {linkURL} className = "button-documentView-footer ">
             See all information
          </Link>

        </div>
        :
        <div className = "helpText">Select a document to see information</div>}
    </div>)



          }
}
export default DocumentPreview;
