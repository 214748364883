import React from 'react';
import SummaryLogs from '../Logs/SummaryLogs';
import Notifications from '../Notifications/Notifications';
import HomeSVG from './home.svg';
import './Home.css';
import Routes from '../../helpers/Routes';
import { Link } from 'react-router-dom';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import CookieWrapper from '../CookieWrapper/CookieWrapper';
import TutorialButton from '../Layout/Help/Onboarding/TutorialButton';
import PendingApprovalButtons from '../Documents/BatchSheetNav/PendingApprovalButtons';
import DocumentRecipeRow from '../Documents/DocumentRecipeRow';
import BatchSheetNavRow from '../Documents/BatchSheetNav/BatchSheetNavRow';
class Home extends React.Component {
	constructor() {
		super();
		this.state = {
			permissions_to_sign: [],
			showAllDocuments: false,
			autogeneratedRecipes: [],
			batchSheets: [],
			selectedBatchSheets: [],
		}
	}
	componentDidMount() {
		this.getOrganization();
		this.getSignatures();
		this.getAutogeneratedRecipes();
		this.onGetBatchSheets();
	}
	getSignatures = () => {
		Request.authRequest(Routes.recipes.signatureList, 'GET').then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.setState({ permissions_to_sign: body, showAllDocuments: false })
				})
			}
			else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	getAllUnsignedSignatures = () => {
		Request.authRequest(Routes.recipes.allSignatures, 'GET').then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.setState({ permissions_to_sign: body, showAllDocuments: true })
				})
			}
			else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	getAutogeneratedRecipes = () => {
		let searchString = '?page=1&per_page=20&status=accepted&tag=Autogenerated'
		Request.authRequest(Routes.recipes.index(searchString),
			'GET').then((response) => {
				if (response.status === 200) {
					response.json().then(body => {


						this.setState({
							autogeneratedRecipes: body.results,
							docListLoaded: true
						})
					})
				}

				else {
					response.json().then((error) => {
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}
	getOrganization = () => {
		Request.authRequest(Routes.organization.index, 'GET').then((response) => {
			if (response.status === 200) {
				response.json().then((body) => {
					if (body.temporary === true) {
						toast.error('You are not part of an organization. Redirecting...');
						window.setTimeout(() => { window.location.href = '/organization/create' }, 3000);

					}
				})
			}
		})
	}
	onGetBatchSheets = () => {
		Request.authRequest(Routes.recipes.batchSheetPendingList,
			'GET').then((response) => {
				if (response.status === 200) {
					response.json().then(body => {
						this.setState({
							batchSheets: body.results,
							numBatchSheetResults: body.total_results,
							selectedBatchSheets: [],
							docListLoaded: true
						})
					})

				}
				else {
					response.json().then((error) => {
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}

	onSelectBatchSheet = (doc) => {
		this.setState(prevState => {
			let activeDocuments = prevState.selectedBatchSheets;
			let selectedDoc = prevState.selectedDocument;
			let activeDocumentsIds = (activeDocuments.map((entity) => { return entity.batch_sheet_id }));

			if (
				activeDocumentsIds.indexOf(doc.batch_sheet_id) > -1
			) {
				activeDocuments = activeDocuments.filter(docs => docs.batch_sheet_id !== doc.batch_sheet_id);
			}
			else {
				activeDocuments = [...activeDocuments, doc];

			}
			if (prevState.selectedDocument !== doc) {
				selectedDoc = doc;
			}
			return { selectedBatchSheets: activeDocuments, selectedDocument: selectedDoc };
		}
		);
	}
	createPaymentInfo = () => {
		Request.authRequest(Routes.payments.edit, 'GET').then((response) => {
			localStorage.setItem('stripe', true);
			return
		})
	}
	goMobile = () => {
	}
	onSelectDocument = () => {
		return
	}
	render() {

		let permissionsToSign = <div className="helpText">No Recipes Pending.</div>
		if (this.state.permissions_to_sign.length > 0) {
			permissionsToSign = this.state.permissions_to_sign.map((permission, i) => {
				return <DocumentRecipeRow document={permission} key={i} onSelectDoc={this.onSelectDocument}
					activeDocuments={this.state.activeDocuments}
					onGetDocuments={this.onGetSignatures} />
			})
		}
		let autogeneratedRecipes = <div className="helpText">No Autogenerated Recipes</div>
		if (this.state.autogeneratedRecipes.length > 0) {
			autogeneratedRecipes = this.state.autogeneratedRecipes.map((permission, i) => {
				return <DocumentRecipeRow document={permission} key={i} onSelectDoc={this.onSelectDocument}
					activeDocuments={this.state.activeDocuments}
					onGetDocuments={this.onGetSignatures} />
			})
		}
		let batchSheets = <div className="helpText">No Batch Sheets</div>
		if (this.state.batchSheets.length > 0) {
			batchSheets = this.state.batchSheets.map((permission, i) => {
				return <BatchSheetNavRow document={permission} key={i} onSelectDoc={this.onSelectBatchSheet}
					activeDocuments={this.state.selectedBatchSheets}
					onGetDocuments={this.onGetBatchSheets} />
			})
		}
		return (
			<div className='home'>
				<div className=' home-left boxShadow' >
					<img src={HomeSVG} alt="Welcome to Ivy!" />
					<div className="home-left-changelog ">

						<div className='welcomeMessage'>
							Welcome to Ivy Recipes. Please email nathan@ivydocuments.com with any feedback
							or if you need assistance with anything as we develop the application!
						</div>
						<div>
							<h4>v1.1.7</h4>
							You can now change values of the bill of materials after a recipe is locked. Changing unit values in the bill of materials does not change values in the saved recipe.
							<h4>v1.1.6</h4>

							<ul>
								<li>Fixes an issue where the display of the sent to column does not expand.</li>
								<li>The name of a batch sheet will now match the recipe name.</li>
								<li>Adds sent to logs and a send button for bill of materials.</li>

							</ul>
						</div>
						<div>

						</div>
					</div>
				</div>
				<div>
					<div className="notifications-container home-container boxShadow">
						<div className='notificationsheader noselect'>
							{this.state.showAllDocuments ? "All Recipes Awaiting Approval" : "Your Recipes Awaiting Approval"}
							{this.state.showAllDocuments ?
								<div className="button-allUnsignedSignatures"
									onClick={this.getSignatures}>
									See Your Unsigned Recipes
								</div>
								: <div className="button-allUnsignedSignatures"
									onClick={this.getAllUnsignedSignatures}>
									See All Unsigned Recipes
								</div>}
						</div>
						<div >
							<div className="tags-docs-table">
								<div className="tags-docs-tableheader-name">Name
								</div>
								<div className="tags-docs-tableheader-ingredients">Ingredients
								</div>
								<div className="tags-docs-tableheader-tags">Tags</div>
							</div>
							<div className='notifications-permissions'>
								{permissionsToSign}
							</div>
						</div>
					</div>
					<div className="notifications-container home-container boxShadow">
						<div className='notificationsheader noselect'>Autogenerated Recipes</div>
						<div >
							<div className="tags-docs-table">
								<div className="home-docs-tableheader-name">Name
								</div>
								<div className="tags-docs-tableheader-ingredients">Ingredients
								</div>
								<div className="tags-docs-tableheader-tags">Tags</div>
							</div>
							<div className='notifications-permissions'>
								{autogeneratedRecipes}
							</div>
						</div>
					</div>
					<div className="notifications-container home-container boxShadow">
						<div className="is-spaced-between">
							<div className='notificationsheader noselect'>Pending Batch Sheets</div>
							<div><PendingApprovalButtons onGetDocuments={this.onGetBatchSheets} activeDocuments={this.state.selectedBatchSheets} /></div>
						</div>
						<div >
							<div className="tags-docs-table">
								<div className="home-docs-tableheader-name">Name
								</div>
								<div className="tags-docs-tableheader-ingredients">Received From
								</div>
								<div className="tags-docs-tableheader-tags">Tags</div>
							</div>
							<div className='notifications-permissions'>
								{batchSheets}
							</div>
						</div>
					</div>
				</div>
				<div className='home-right boxShadow'>
					<SummaryLogs />
				</div>

			</div>
		);
	}
}

export default CookieWrapper(Home);
