import React from 'react';
import './BatchSheetViewer.css';
class BatchSheetRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weight_basis_kg: 100,
        }
    }
    render() {
        let ingredient = this.props.ingredient;
        
        return (
            <tr className="batchSheetTable-row">
                <td className="wordbreak ingredient-name">
                    {ingredient.ingredient_name}
                </td>
                <td className="wordbreak ingredient-percent">
                    {ingredient.weight_ratio}
                </td>

                <td className="wordbreak batchSheet-field">
                    {ingredient.f_weight_basis_kg}
                </td>
                <td className="wordbreak batchSheet-field">
                    {ingredient.f_weight_basis_lb}
                </td>
                {this.props.ignore_density ? null : <td className="wordbreak batchSheet-field">
                    {ingredient.f_vol_basis_L}
                </td>}
                {this.props.ignore_density ? null : <td className="wordbreak batchSheet-field">
                    {ingredient.f_vol_basis_gal}
                </td>}
                {this.props.ignore_density ? null : <td className="wordbreak batchSheet-minor-field">
                    {ingredient.f_density_kg_L}
                </td>}
                {this.props.ignore_density ? null : <td className="wordbreak batchSheet-minor-field">
                    {ingredient.f_density_lb_gal}
                </td>}
            </tr>
        )
    }
}
export default BatchSheetRow;