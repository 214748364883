import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import BillOfMaterials from '../BatchSheetViewer/BillOfMaterials';
import { BOM } from '../../Icons/IconLibrary';
import InfoField from '../../Documents/InfoField';
import { withRouter } from 'react-router-dom';
import CookieWrapper from '../../CookieWrapper/CookieWrapper';
import SendBOMToButton from '../BatchSheetViewer/SendBOMToButton';
import ShortLogsRow from '../../Logs/ShortLogsRow';
import './BillofMaterialsViewer.css'
class BillofMaterialsViewer extends React.Component {
    constructor(props) {
        super(props);
        this.onGetRecipe = this.onGetRecipe.bind(this);
        this.onGetLogs = this.onGetLogs.bind(this);
        this.state = {
            showModal: false,
            recipe: '',
            logs: [],
        }
    }
    componentDidMount = () => {
        this.onGetRecipe();
        this.onGetLogs();
    }
    onGetLogs() {
        Request.authRequest(Routes.logs.billOfMaterials(this.props.match.params.id), 'GET').then((response) => {
            response.json().then(body => {
                this.setState({ logs: body.results })
                console.log(body)
            })

        })
    }
    onReturnNavigation = () => {
        window.location.href = window.location.pathname.slice(0, -4)
    }
    onGetRecipe() {
        Request.authRequest(Routes.recipes.edit(this.props.match.params.id),
            'GET').then((response) => {

                if (response.status === 200) {
                    response.json().then(body => {
                        this.setState({ recipe: body, loaded: true })
                    })
                }
                else {
                    response.json().then((error) => {
                        this.setState({ loaded: true })
                        toast.error(error.message);
                    });
                }
            }).catch((error) => {
                toast.error(error.message);
            }
            )
    }
    render() {
        let recipe = this.state.recipe.recipe;
        let doc = this.state.recipe;
        let versionNumber;
        let logsList = <div className="helpText">No logs for this recipe.</div>;
        if (this.state.logs.length > 0) {
            logsList = this.state.logs.map((entity, i) => {
                return <ShortLogsRow log={entity} key={i} />
            })
            console.log(3)
        }
        if (doc) {
            if (doc.version_history_with_self.length > 1) {
                versionNumber = 'Version ' + doc.version_number;
                if (doc.version_history_with_self.length > 1 && doc.version_number === doc.version_history_with_self.length - 1
                ) {
                    versionNumber = versionNumber + " - current";
                } else if (doc.version_history_with_self.length > 1 &&
                    doc.version_number > doc.version_history_with_self[0].version_number) {
                    versionNumber = versionNumber + " - current";
                }
            }
        }
        return (
            <div className="BOM-viewerPage-majorContainer">
                <div className="recipe-main boxShadow">
                    <button className='button-ReturnViewer' onClick={this.onReturnNavigation}> Back to Recipes </button>
                    <div className="BOM-viewerpage-container">
                        <div className="recipeInfo BOM-viewer-container">

                            <div className="BOM-viewer-container">
                                <div className="recipe-title">{doc.name} </div>

                                <div>{versionNumber}</div>
                                <div>{doc.user_defined_id}</div>
                                <div className="recipe-title">Bill of Materials</div>
                                <BillOfMaterials recipe={recipe} onGetRecipe={this.onGetRecipe} />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='document-viewer-rightColumn boxShadow ' id="bom-log-majorcontainer">
                    <div className="document-viewer-rightColumn-header" >

                        <div className="document-viewer-column-header-text" id="bom-log-container">
                            <div className="document-viewer-tabActive">
                                <div className="BillOfMaterials-logs-headers">
                                    <div>Logs</div>
                                    <SendBOMToButton doc={doc} recipe={recipe} onGetLogs={this.onGetLogs} />
                                </div>
                            </div>

                            <div className='logSummary'>

                                <div className="logSummary-contents">
                                    {logsList}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default withRouter(CookieWrapper(BillofMaterialsViewer));